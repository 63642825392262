import { cn } from "~/utils/cn";

export type GridRowProps = {
  className?: string;
  children: React.ReactNode;
  autoResponsiveGrid?: boolean;
  autoGridLines?: boolean;
  widthOverflow?: boolean;
  paddingPageTop?: boolean;
  paddingDefault?: boolean;
  removeBorderT?: boolean;
  removeBordersX?: boolean;
};

export function GridRow({
  className,
  children,
  autoResponsiveGrid = true,
  autoGridLines = true,
  widthOverflow = false,
  paddingPageTop = false,
  removeBorderT = false,
  removeBordersX = false,
}: GridRowProps) {
  return (
    <div
      className={cn(
        "relative grid justify-center border-grid-dimmed",
        // Changes grid row to single column on mobile
        autoResponsiveGrid ? "grid-cols-1 sm:grid-cols-12" : "grid-cols-12",
        // Automatically adds dividing lines between gridRows. Turn off if adding custom grid lines
        autoGridLines
          ? "divide-x divide-y divide-grid-dimmed sm:divide-y-0"
          : "divide-x-0 divide-y-0",
        // Adds pt to the top section of a page, to account for the nav
        paddingPageTop ? "pt-[60px]" : "pt-0",
        // Removes top border if widthOverflow section above
        removeBorderT ? "border-t-0" : "border-t",
        // Adds a full width row with a max-width of 90rem. Used for horizontal-scrollable content
        widthOverflow
          ? "z-20 max-w-[90rem] border-x-0 border-y bg-background py-0 sm:py-0"
          : "mx-3 max-w-[76.5rem] grid-rows-[1fr_auto] xl:mx-auto",
        removeBordersX ? "" : "border-x",
        className
      )}
    >
      {widthOverflow && (
        <>
          <div className="absolute bottom-[-18px] left-0 top-[-18px] z-10 hidden w-px bg-grid-dimmed xl:block" />
          <div className="absolute bottom-[-18px] right-0 top-[-18px] z-10 hidden w-px bg-grid-dimmed xl:block" />
        </>
      )}

      {children}
    </div>
  );
}

export type GridSectionProps = {
  className?: string;
  children?: React.ReactNode;
  removePaddingX?: boolean;
  removePaddingY?: boolean;
  justifyCenter?: boolean;
  flipContentOrder?: boolean;
  defaultColSpans?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
};

export function GridContent({
  children,
  removePaddingX = false,
  removePaddingY = false,
  justifyCenter,
  flipContentOrder,
  defaultColSpans,
  className,
  ref,
  onMouseOver,
  onMouseLeave,
  onMouseEnter,
}: GridSectionProps) {
  return (
    <div
      className={cn(
        "grid",
        removePaddingX ? "" : "px-3",
        removePaddingY ? "" : "py-3",
        defaultColSpans ? "col-span-1 sm:col-span-12" : "",
        justifyCenter ? "items-center" : "",
        flipContentOrder ? "order-reverse" : "",
        className
      )}
      ref={ref}
      onMouseOver={onMouseOver}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
}
